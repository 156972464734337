import { API, API_GET, API_POST } from "../../config";

export const GetService = () => {
  return API_GET(API.Service);
};

export const ServiceDelete = (id, title) => {
  console.log(title);

  const f = new FormData();
  f.append("id", id);
  if (title === "Services") {
    return API_POST(API.ServiceDelete, f);
  } else if (title === "Designation") {
    return API_POST(API.DesignationDelete, f);
  } else if (title === "Education") {
    return API_POST(API.EducationDelete, f);
  } else {
    return API_POST(API.ExperienceDelete, f);
  }
};

export const AddServices = (data, title) => {
  console.log(title);

  const f = new FormData();
  f.append("name", data);

  if (title === "Services") {
    return API_POST(API.AddService, f);
  } else if (title === "Designation") {
    return API_POST(API.AddDesignation, f);
  } else if (title === "Education") {
    return API_POST(API.AddEducation, f);
  } else {
    const g = new FormData();
    g.append("experience", data);
    return API_POST(API.AddExperience, g);
  }
};

export const EditServices = (text, settingId, title) => {

  const f = new FormData();
  f.append("id", settingId);
  f.append("name", text);

  if (title === "Services") {
    return API_POST(API.editservice, f);
  } else if (title === "Designation") {
    return API_POST(API.editDesignation, f);
  } else if (title === "Education") {
    return API_POST(API.editEducation, f);
  } else {
    const g = new FormData();
    g.append("id", settingId);
    g.append("experience", text);
    return API_POST(API.editExperience, g);
  }
};
