import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../../Components/Inputs/CustomInput";
import CustomButton from "../../../Components/Buttons/CustomButton";
import { AddFAQ } from "../../../Apis/Functions/FAQ/AddFAQ";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetFaq } from "../../../Apis/Functions/FAQ/GetFAQ";
import bin from "../../../Utils/Images/bin.svg";
import cross from "../../../Utils/Images/cross.png";
import Edit from "../../../Utils/Images/Edit.png";
import { DeleteFaq } from "../../../Apis/Functions/FAQ/DeleteFaq";
import DeleteConfirmationModal from "../../../Components/Dashboard/Modal/DeleteConfitmModal";
import * as Yup from "yup";
import { EditFaq } from "../../../Apis/Functions/FAQ/EditFAQ";
import { Snackbar, SnackbarContent } from "@mui/material";

const Faq = () => {
  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Question is required"),
    answer: Yup.string().required("Answer is required"),
  });
  const editValidationSchema = Yup.object().shape({
    editquestion: Yup.string().required("Question is required"),
    editanswer: Yup.string().required("Answer is required"),
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });
  const initialData = {
    question: "",
    answer: "",
  };
  const [formDatab, setFormDatab] = useState({
    editquestion: "",
    editanswer: "",
  });

  const handleOpenModal = (e) => {
    console.log(e);
    setUserId(e);
    setFormDatab({
      ...formDatab,
      editquestion: e.question,
      editanswer: e.answer,
    });
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleEditClose = () => {
    setEditopen(false);
  };
  const handleEditOpen = (e) => {
    setUserId(e.id);
    setFormDatab({
      ...formDatab,
      editquestion: e.question,
      editanswer: e.answer,
    });
    setEditopen(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    Yup.reach(validationSchema, name)
      .validate(value)
      .then(() => {
        setErrors({
          ...errors,
          [name]: "",
        });
      })
      .catch((error) => {
        setErrors({
          ...errors,
          [name]: error.message,
        });
      });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    Yup.reach(editValidationSchema, name)
      .validate(value)
      .then(() => {
        setErrors({
          ...errors,
          [name]: "",
        });
      })
      .catch((error) => {
        setErrors({
          ...errors,
          [name]: error.message,
        });
      });

    setFormDatab({
      ...formDatab,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const trimmedFormData = {
        question: formData.question.replace(/\s+/g, " ").trim(),
        answer: formData.answer.replace(/\s+/g, " ").trim(),
      };

      // Check if trimmed form data is not empty
      if (trimmedFormData.question !== "" && trimmedFormData.answer !== "") {
        validationSchema
          .validate(trimmedFormData, { abortEarly: false })
          .then(() => {
            AddFAQ(trimmedFormData)
              .then((res) => {
                setFormData(initialData);
                setLoading(false);
                GetFAQ();
              })
              .catch((error) => {
                setLoading(false);
                console.log(error);
              });
          })
          .catch((validationErrors) => {
            const newErrors = {};
            validationErrors.inner.forEach((error) => {
              newErrors[error.path] = error.message;
            });
            setErrors(newErrors);
            setLoading(false);
          });
      } else {
        // Handle the case where the form is blank
        setLoading(false);
        console.log("Form is blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const GetFAQ = async () => {
    try {
      setLoading(true);
      let res = await GetFaq();
      console.log(res);
      setLoading(false);
      setFaqData(res.r);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      let res = await DeleteFaq(userId, 0);
      setOpen(false);
      GetFAQ();
      showSnackbar("Delete successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmEdit = async () => {
    setLoading(true);
    try {
      const cleanedFormDatab = { ...formDatab };
      for (const key in cleanedFormDatab) {
        if (Object.hasOwnProperty.call(cleanedFormDatab, key)) {
          cleanedFormDatab[key] = cleanedFormDatab[key]
            .replace(/\s+/g, " ")
            .trim();
        }
      }

      editValidationSchema
        .validate(cleanedFormDatab, { abortEarly: false })
        .then(() => {
          EditFaq(cleanedFormDatab, userId)
            .then((res) => {
              setEditopen(false);
              GetFAQ();
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        })
        .catch((validationErrors) => {
          const newErrors = {};
          validationErrors.inner.forEach((error) => {
            newErrors[error.path] = error.message;
          });
          setErrors(newErrors);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    GetFAQ();
  }, []);

  
  return (
    <Box
      sx={{
        ml: { xs: "1px", md: "300px" },
        p: 3,
        boxSizing: "border-box",
        bgcolor: "#EDEDED",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <Typography variant="h4">Add Frequently Asked Questions</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: "10px",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <CustomInput
              name="question"
              onChange={handleChange}
              value={formData.question}
              autoComplete="off"
              placeholder={"Add Question"}
              error={!!errors.question}
              helperText={errors.question}
              sx={{
                bgcolor: "#fff",
                borderRadius: "10px",
                width: "50%",
              }}
            />
          </Box>{" "}
          <Box>
            <CustomInput
              name="answer"
              onChange={handleChange}
              value={formData.answer}
              autoComplete="off"
              placeholder={"Add Answer"}
              error={!!errors.answer}
              helperText={errors.answer}
              sx={{
                bgcolor: "#fff",
                borderRadius: "10px",
                width: "50%",
              }}
            />
          </Box>
          <CustomButton onClick={handleSubmit} sx={{ width: "50%", m: "auto" }}>
            Submit
          </CustomButton>
        </Box>
      </Box>
      <Box sx={{ width: "80%", m: "auto", textAlign: "center", mt: "50px" }}>
        <Typography variant="h4">Frequently Asked Questions</Typography>

        {faqData?.map((e, i) => (
          <Box key={i}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                mt: "10px",
              }}
            >
              <Accordion sx={{ width: "100%", overflow: "hidden", m: "auto" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{e.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{e.answer}</Typography>
                </AccordionDetails>
              </Accordion>

              <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    mt: "10px",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleEditOpen(e)}
                >
                  <img
                    width={"100%"}
                    height={"100%"}
                    src={Edit}
                    alt="preview"
                  />
                </Box>
                <Box
                  sx={{
                    mt: "10px",
                    width: "45px",
                    height: "45px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleOpenModal(e.id)}
                >
                  <img width={"100%"} height={"100%"} src={bin} alt="preview" />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <DeleteConfirmationModal
        open={open}
        onClose={handleCloseModal}
        onConfirmDelete={() => handleConfirmDelete(userId)}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000} // Adjust the duration as needed
        onClose={() => setSnackbarOpen(false)}
      >
        <SnackbarContent
          message={snackbarMessage}
          sx={{
            backgroundColor: "#4CAF50", // Set the desired background color
          }}
        />
      </Snackbar>

      <Modal
        open={editopen}
        onClose={handleEditClose}
        onconfirmEdit={handleConfirmEdit}
      >
        <Box
          sx={{
            position: "absolute",
            outline: "none",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            borderRadius: "30px",
            gap: "20px",
            boxShadow: 24,
            width: "40%",
            height: "200px",
            position: "relative",
          }}
        >
          <Box
            onClick={handleEditClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
          >
            <img width={"100%"} height={"100%"} src={cross} alt="preview" />
          </Box>
          <Box
            sx={{
              backgroundColor: "#8ADCD9",
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: "10px",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <CustomInput
                name="editquestion"
                onChange={handleEditChange}
                value={formDatab.editquestion}
                autoComplete="off"
                placeholder={"Add Question"}
                error={!!errors.editquestion}
                helperText={errors.editquestion}
                sx={{
                  bgcolor: "#fff",
                  borderRadius: "10px",
                  width: "50%",
                }}
              />
            </Box>{" "}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CustomInput
                name="editanswer"
                onChange={handleEditChange}
                value={formDatab.editanswer}
                autoComplete="off"
                placeholder={"Add Answer"}
                error={!!errors.editanswer}
                helperText={errors.editanswer}
                sx={{
                  bgcolor: "#fff",
                  borderRadius: "10px",
                  width: "50%",
                }}
              />
            </Box>
            <CustomButton
              onClick={handleConfirmEdit}
              sx={{
                width: "50%",
                m: "auto",
                bgcolor: "#000",
                color: "#fff",
                "&:hover": {
                  bgcolor: "#000",
                  color: "#fff",
                },
              }}
            >
              Submit
            </CustomButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Faq;
