import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import SettingCard from "../../../../Components/Setting/SettingCard/SettingCard";
import { GetDesignation } from "../../../../Apis/Functions/List/Designation";
import { GetService } from "../../../../Apis/Functions/List/Service";
import { GetEducation } from "../../../../Apis/Functions/List/Education";
import { GetExperience } from "../../../../Apis/Functions/List/Experience";

const Setting = () => {
  const [designation, setDesignation] = useState([]);
  const [service, setService] = useState([]);
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);

  const handleDesignation = async () => {
    try {
      let res = await GetDesignation();
      setDesignation(res.r);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEducation = async () => {
    try {
      let res = await GetEducation();
      setEducation(res.r);
    } catch (error) {
      console.log(error);
    }
  };
  const handleService = async () => {
    try {
      let res = await GetService();
      setService(res.r);
    } catch (error) {
      console.log(error);
    }
  };
  const handleExperience = async () => {
    try {
      let res = await GetExperience();
      setExperience(res.r);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleDesignation();
    handleService();
    handleEducation();
    handleExperience();
  }, []);

  return (
    <Box
      sx={{
        ml: { xs: "1px", md: "300px" },
        bgcolor: "#EDEDED",
        p: 2,
        boxSizing: "border-box",
        height: { md: "auto", lg: "100vh" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          // justifyContent: "space-evenly",
          gap: "10px",
        }}
      >
        <SettingCard data={service} title={"Services"} />
        <SettingCard data={designation} title={"Designation"} />
        <SettingCard data={education} title={"Education"} />
        <SettingCard data={experience} title={"Experience"} />
      </Box>
    </Box>
  );
};

export default Setting;
