// DashboardRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateUser from "../Dashboard/Component/PrivateUser/PrivateUser";
import BusinessUser from "../Dashboard/Component/BusuinessUser/BusinessUser";
import DashboardHome from "../Dashboard/Component/Dashboard/Dashboard";
import Sidebar from "../../Components/Dashboard/Sidebar/Sidebar";
import { Box } from "@mui/material";
import Setting from "../Dashboard/Component/Setting/Setting";
import PremiumUser from "../Dashboard/Component/PremiumUser/PremiumUser";
import Faq from "../Dashboard/Faq/Faq";
import ContactList from "../Dashboard/ContactList/ContactList";
import ReportedList from "../Dashboard/ReportedList/ReportedList";

const DashboardRoutes = () => {
  return (
    <Box height={"100vh"}>
      <Sidebar />
      <Routes>
        <Route path="/" element={<DashboardHome />} />
        <Route path="/privateUser" element={<PrivateUser />} />
        <Route path="/businessUser" element={<BusinessUser />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/premiumuser" element={<PremiumUser />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/reported" element={<ReportedList />} />
        <Route path="/contactlist" element={<ContactList />} />
      </Routes>
    </Box>
  );
};

export default DashboardRoutes;
