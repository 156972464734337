import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  easing,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import eye from "../../../../Utils/Images/eye.svg";
import { Link } from "react-router-dom";
import bin from "../../../../Utils/Images/bin.svg";
import DeleteConfirmationModal from "../../Modal/DeleteConfitmModal";
import { DeleteReport } from "../../../../Apis/Functions/ReportList/DeleteReport";
const ReportTable = ({ data, type, handleReportList }) => {
  const [open, setOpen] = useState(false);
  const [reportId, setReportId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleOpenModal = (id) => {
    setOpen(true);
    setReportId(id);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleConfirmDelete = async (reportId) => {
    console.log(reportId);
    try {
      let res = await DeleteReport(reportId, 0);
      if (res.s) {
        setOpen(false);
        handleReportList();
        showSnackbar("Delete successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box>
      <Box
        sx={{
          p: "0 20px 0px 20px",
          boxSizing: "border-box",
          overflow: "auto",
          "&:hover": {
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "3px",
            },
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "start",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  textAlign: "center",
                }}
              >
                #
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "start",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  textAlign: "center",
                }}
              >
                Username
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "left",
                  position: "sticky",
                  top: 0,
                  bgcolor: "white",
                  zIndex: 1,
                  textAlign: "center",
                }}
              >
                email
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  lineHeight: "0.3",
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#9C9C9C",
                    bgcolor: "white",
                    textAlign: "center",
                  }}
                >
                  Reported By
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "center",
                }}
              >
                Reason
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "center",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, i) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "100%",
                      "&:hover": {
                        overflow: "visible",
                      },
                      textAlign: "center",
                    }}
                  >
                    {i + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "100%",
                      "&:hover": {
                        overflow: "visible",
                      },
                      textAlign: "center",
                    }}
                  >
                    {row?.report_to_details?.user_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "300px",
                      "&:hover": {
                        overflow: "visible",
                      },
                    }}
                  >
                    {row?.report_to_details?.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "120px",
                      "&:hover": {
                        overflow: "visible",
                      },
                      textAlign: "center",
                    }}
                  >
                    {row?.report_by_details?.user_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Tooltip
                    sx={{ fontFamily: "inter" }}
                    title={row?.report_reason}
                    arrow
                  >
                    <Typography
                      noWrap
                      sx={{
                        cursor: "pointer",
                        m: "auto",
                        fontFamily: "inter",
                        fontWeight: "400",
                        fontSize: "16px",
                        width: "170px",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                      }}
                    >
                      {row?.report_reason}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        onClick={(e) => handleOpenModal(row.id)}
                        sx={{ width: "30px", height: "30px" }}
                      >
                        <img
                          height={"100%"}
                          width={"100%"}
                          style={{ cursor: "pointer" }}
                          src={bin}
                          alt="logo"
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "30px", height: "30px" }}>
                        <Link to={`/${type}User/${row?.report_to_details?.id}`}>
                          <img
                            height={"100%"}
                            width={"100%"}
                            style={{ cursor: "pointer" }}
                            src={eye}
                            alt="logo"
                          />
                        </Link>
                      </Box>
                    </Box>{" "}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <DeleteConfirmationModal
        open={open}
        onClose={handleCloseModal}
        onConfirmDelete={() => handleConfirmDelete(reportId)}
      />
    </Box>
  );
};

export default ReportTable;
