import logo from "../../../Utils/Images/logo.png";
import title from "../../../Utils/Images/title.svg";
import exit from "../../../Utils/Images/Logout 2.svg";
import { Box, Divider, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import sidebarArray from "./Sidebar.dto";
import CustomButton from "../../Buttons/CustomButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../../../ContextProvider/AuthProvider/AuthProvider";

const Sidebar = () => {
  const location = useLocation().pathname;
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const { logout } = useContext(AuthContext);
  const handleResize = () => {
    window.innerWidth <= 900 ? setDrawerOpen(false) : setDrawerOpen(true);
  };
  const handleDrawerToggle = () => {
    window.innerWidth <= 900 ? setDrawerOpen(!drawerOpen) : setDrawerOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogoutConfirmed = () => {
    logout();
    setOpen(false);
  };
  useEffect(() => {
    handleResize(); // Initial check to set the state based on the screen size when the component mounts
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* Mobile Menu */}
      <IconButton
        sx={{ display: { md: "none", sm: "block" } }}
        onClick={handleDrawerToggle}
      >
        <MenuIcon />
      </IconButton>

      {/* Drawer (for smaller screens) */}
      <Drawer
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        {/* Sidebar Content */}
        <Box
          sx={{
            bgcolor: "white",
            width: "265px",
            padding: "20px",
            height:"80vh",
            overflow:'auto',
          }}
        >
          <Box
            sx={{
              m: "auto",
              mt: "50px",
              display: "flex",
              justifyContent: "space-between",
              width: "65%",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="logo" />
            <img style={{ marginTop: "10px" }} src={title} alt="logo" />
            <IconButton
              onClick={handleDrawerToggle}
              sx={{
                position: "absolute",
                display: { md: "none", sm: "block" },
                top: 0,
                left: 0,
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ width: "70%", m: "auto", mt: "50px" }}>
            {sidebarArray.map((e, i) => (
              <Link key={i} style={{ textDecoration: "none" }} to={e.path}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "90%",
                      m: "auto",
                      mt: "10px",
                      alignItems: "center",
                      "&:hover": {
                        transition: "transform 0.2s",
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <img 
                      width={"30px"}
                      height={"30px"}
                      src={e.path === location ? e.active : e.image}
                      alt="logo"
                    />

                    <Box onClick={handleDrawerToggle}>
                      <Typography
                        sx={{
                          ml: "10px",
                          textAlign: "left",
                          fontFamily: "Segoe UI",
                          fontSize: "20px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          cursor: "pointer",
                          color: e.path === location ? "#01696B" : "#626262",
                        }}
                      >
                        {e.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider
                    sx={{ mt: "25px", mb: "15px", border: "1px solid #C4C4C4" }}
                  ></Divider>
                </Box>
              </Link>
            ))}
          </Box>
          <Box
            sx={{
              width: "240px",
              position: "fixed",
              bottom: 20,
              left: 50,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                ml: "20px",
                "&:hover": {
                  transition: "transform 0.2s",
                  transform: "scale(1.1)",
                },
              }}
            >
              <img src={exit} alt="logo" />
              <Typography sx={{ cursor: "pointer" }} onClick={handleClickOpen}>
                Log Out
              </Typography>
            </Box>
          </Box>
        </Box>
      </Drawer>

      {/* Main Content */}

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            borderRadius: "20px",
            outline: "none",
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to log out?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <HelpOutlineIcon
              sx={{ width: "75px", height: "100px", color: "#8ADCD9" }}
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 5, mt: 2 }}
          >
            <CustomButton onClick={handleLogoutConfirmed}>Yes</CustomButton>
            <CustomButton onClick={handleClose}>No</CustomButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Sidebar;
