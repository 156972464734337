import widget from "../../../Utils/Images/Widget.svg";
import user from "../../../Utils/Images/User Rounded.svg";
import suitcase from "../../../Utils/Images/Suitcase.svg";
import suitcaselines from "../../../Utils/Images/Suitcase Lines.svg";
import userfill from "../../../Utils/Images/User Rounded fill.svg";
import widgetfill from "../../../Utils/Images/Widget fill.svg";
import settingfill from "../../../Utils/Images/setting.svg";
import setting from "../../../Utils/Images/Setting outline.svg";
import diamond from "../../../Utils/Images/diamond.png";
import diamondfill from "../../../Utils/Images/diamond fill.png";
import faq from "../../../Utils/Images/FAQ.png";
import faqfill from "../../../Utils/Images/FAQfill.png";
import contact from "../../../Utils/Images/contact.png";
import report from "../../../Utils/Images/report.png";
import reportfill from "../../../Utils/Images/reportfill.png";
import contactfill from "../../../Utils/Images/contactfill.png";
const sidebarArray = [
  { active: widgetfill, image: widget, title: "Dashboard", path: "/" },
  {
    active: userfill,
    image: user,
    title: "Private User",
    path: "/privateUser",
  },
  {
    active: suitcaselines,
    image: suitcase,
    title: "Business User",
    path: "/businessUser",
  },
  {
    active: diamondfill,
    image: diamond,
    title: "Premium User",
    path: "/premiumuser",
  },
  {
    active: faqfill,
    image: faq,
    title: "FAQ",
    path: "/faq",
  },
  {
    active: contactfill,
    image: contact,
    title: "Contact List",
    path: "/contactlist",
  },
  {
    active: reportfill,
    image: report,
    title: "Report List",
    path: "/reported",
  },
  {
    active: settingfill,
    image: setting,
    title: "Settings",
    path: "/setting",
  },
];

export default sidebarArray;
