import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "../../Buttons/CustomButton";
const DeleteConfirmationModal = ({ open, onClose, onConfirmDelete }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          outline: "none",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "20px",
          gap: "20px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Are you sure you want to delete this ?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <DeleteIcon
            sx={{ height: "80px", color: "#8ADCD9", width: "70px" }}
          />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", gap: "20px", mt: 2 }}
        >
          <CustomButton
            sx={{ bgcolor: "#8ADCD9" }}
            onClick={onConfirmDelete}
            variant="contained"
          >
            Yes, delete it!
          </CustomButton>
          <CustomButton
            sx={{ bgcolor: "#8ADCD9", color: "black" }}
            onClick={onClose}
            variant="contained"
          >
            Cancel
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
