import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";


const Details = ({singleUser}) => {
  console.log(singleUser)
  return (
    <Box>
      <Paper
        sx={{
          p: 4,
          textAlign: "center",
          color: "text.secondary",
          boxSizing: "border-box",
          borderRadius: "23px",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#858585",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
           {singleUser?.account_type === 1 ? 'User Details': "Business Details"}
          </Typography>
          <Divider sx={{ width: "100%", mt: 2 }} />

          <Box
            sx={{
              height: "75vh",
              overflow: "auto",
              "&:hover": {
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "3px",
                },
              },
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Full Name
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.corporate_name || singleUser?.f_name + " " + singleUser?.l_name }
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>

            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Email ID
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.email}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Username
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.user_name}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Gender
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.gender===1?'Male':"Female"}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Birthdate
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.birthdate}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Designation
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.designation}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Portfolio/Website
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.website}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Experience
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.experience}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Details;
