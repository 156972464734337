import React, { useContext, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import bg from "../../../../../Utils/Images/Login_bg.png";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../../../../../Components/Inputs/CustomInput";
import CustomButton from "../../../../../Components/Buttons/CustomButton";
// import { AuthContext } from "../../../../../ContextProvider/AuthProvider/AuthProvider";
import { API, API_POST } from "../../../../../Apis/config";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  // const { loginParent, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setLoading(false);
      return "Email is required";
    }
    if (!emailRegex.test(email)) {
      setLoading(false);
      return "Invalid email format";
    }
    return "";
  };

  const handleSubmit = async () => {
    setLoading(true);
    const emailError = validateEmail();
    setErrors({
      email: emailError,
    });
    if (!emailError) {
      const f = new FormData();
      f.append("email", email);
      f.append("password", password);
      try {
        const data = await API_POST(API.login, f);
        setLoading(false);
        if (data && data?.s) {
          localStorage.setItem("apikey", data?.r.apikey);
          localStorage.setItem("token", data?.r.token);
          navigate("/");
        } else {
          setLoading(false);
          alert(data.m);
          console.error("Login failed:");
        }
      } catch (error) {
        // Handle login error
        setLoading(false);
        console.error("Login failed:", error);
      }
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        objectFit: "cover",
        fill: "Background",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "cover",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "80%", sm: "60%", md: "40%", xl: "30%" },
          padding: { xs: "25px", md: "35px", xl: "45px" },
          borderRadius: "30px",
          bgcolor: "white",
          m: "auto",
          boxShadow: "10px 10px 26px 0px #0000001A",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            fontSize: { xs: "25px", md: "32px", xl: "39px" },
            fontWeight: "700",
            lineHeight: "47px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          Login
        </Typography>
        <Box
          sx={{
            width: "273px",
            textAlign: "left",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontSize: { xs: "15px", md: "20px", xl: "25px" },
              fontWeight: "400",
              lineHeight: "30px",
              textAlign: "left",
            }}
          >
            Welcome Back Please Enter Your Login Details
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            mt: "10px",
          }}
        >
          <CustomInput
            variant="outlined"
            onChange={handleEmail}
            placeholder="Email Address"
            error={Boolean(errors.email)}
            helperText={errors.email}
            sx={{
              mt: "5px",
              textAlign: "center",
              bgcolor: "#F2F2F2",
              borderRadius: "15px",
              fontSize: { xs: "8px", md: "10px", xl: "34px" },
            }}
          />

          <CustomInput
            variant="outlined"
            onChange={handlePassword}
            placeholder="Password"
            type="password"
            sx={{
              mt: "15px",
              textAlign: "center",
              bgcolor: "#F2F2F2",
              borderRadius: "15px",
              fontSize: { xs: "8px", md: "10px", xl: "20px" },
            }}
          />

          <CustomButton
            disabled={loading}
            sx={{
              mt: "15px",
              p: 3,
              height: { xs: "25px", md: "40px", xl: "60px" },
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress
                sx={{
                  height: { xs: "20px", md: "30px", xl: "40px" },
                  width: { xs: "20px", md: "30px", xl: "40px" },
                }}
                color="inherit"
              />
            ) : (
              "Login"
            )}
          </CustomButton>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Link
            style={{ textDecoration: "none", color: "#000000" }}
            to={"/forgot"}
          >
            <Typography
              sx={{
                
                color: "#000000",
                fontFamily: "Segoe UI",
                fontSize: { xs: "12px", md: "16px", xl: "20px" },
                fontWeight: "400",
                lineHeight: "24px",
                textAlign: "right",
                mt: "15px",
              }}
            >
              Forgot Password ?
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
