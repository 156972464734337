import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";

const OtherDetails = ({ singleUser }) => {
  return (
    <Box>
      <Paper
        sx={{
          p: 4,
          textAlign: "center",
          color: "text.secondary",
          boxSizing: "border-box",
          borderRadius: "23px",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#858585",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Other Details
          </Typography>
          <Divider sx={{ width: "100%", mt: 2 }} />

          <Box
            sx={{
              // height: "75vh",
           
              "&:hover": {
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "3px",
                },
              },
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                 {singleUser?.account_type === 1 ? ' About User': "About Business"}
               
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.about}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            {/* <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Height
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser.height}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Drinking
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser.drinking}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Smokes
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser.smoking}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Pets
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser.pets}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Workout
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser.workout}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Nutrition
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser.nutrition}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box> */}
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                
                {singleUser?.account_type === 1 ? 'Living City': "Business Address"}
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.city}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Language
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.language}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Linkedin Link
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.fb_link}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>{" "}
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Instagram Link
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.insta_link}
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default OtherDetails;
