import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import circle from "../../../../../Utils/Images/Close Circle.svg";
const Servicelooking = ({ singleUser }) => {
  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Paper
            sx={{
              p: 2,
              color: "text.secondary",
              borderRadius: "23px",
            }}
          >
            <Typography
              sx={{
                color: "#858585",
                textAlign: "left",
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Service Looking for
            </Typography>
            <Box>
              <Box
                sx={{
                  mt: 2,
                }}
              >
                {singleUser?.service_look_name?.map((e) => (
                  <Box
                    sx={{
                      background: "#196664",
                      borderRadius: "16px",
                      mt: 1,
                      color: "#F9F9F9",
                      p: 1,
                      textAlign: "start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontFamily: "Neue Montreal",
                          fontWeight: 400,
                        }}
                      >
                        {e.name}
                      </Typography>
                      <Box>
                        <img src={circle} alt="" />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              color: "text.secondary",
              borderRadius: "23px",
            }}
          >
            <Typography
              sx={{
                color: "#858585",
                textAlign: "left",
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Service Providing
            </Typography>
            <Divider sx={{ width: "100%", mt: 2 }} />
            <Box>
              <Typography
                sx={{
                  color: "#858585",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                  mt: "10px",
                }}
              >
                Industry
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                {singleUser?.service_provide }
              </Typography>
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Box>
            <Box>
              {singleUser?.service_provide_name?.map((e) => (
                <Box
                  sx={{
                    background: "#196664",
                    borderRadius: "16px",
                    mt: 1,
                    color: "#F9F9F9",
                    p: 1,
                    textAlign: "start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Neue Montreal",
                        fontWeight: 400,
                      }}
                    >
                      {e.name}
                    </Typography>
                    <Box>
                      <img src={circle} alt="" />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                mt: 2,
              }}
            ></Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Servicelooking;
