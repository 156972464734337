import React, { useState } from "react";
import { Modal, Box, Typography, Input } from "@mui/material";
import CustomButton from "../../Buttons/CustomButton";
import CustomInput from "../../Inputs/CustomInput";

const UpdateModal = ({ open, onClose, onConfirmUpdate, text, setText }) => {
  console.log(text, "t");
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          outline: "none",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "20px",
          gap: "20px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Are you sure you want to Update this ?
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CustomInput
            value={text}
            onChange={(e) => setText(e.target.value)}
            sx={{
              width: "80%",
              m: "auto",
              bgcolor: "lightgrey",
              borderRadius: "15px",
            }}
          />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", gap: "20px", mt: 2 }}
        >
          <CustomButton
            sx={{ bgcolor: "#8ADCD9" }}
            onClick={() => onConfirmUpdate(text)}
            variant="contained"
          >
            Update
          </CustomButton>
          <CustomButton
            sx={{ bgcolor: "#8ADCD9", color: "black" }}
            onClick={onClose}
            variant="contained"
          >
            Cancel
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateModal;
