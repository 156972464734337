import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import eye from "../../../../Utils/Images/eye.svg";
import bin from "../../../../Utils/Images/bin.svg";
import block from "../../../../Utils/Images/User Block.png";
import redblock from "../../../../Utils/Images/Red User Block.png";
import { Link } from "react-router-dom";
import DeleteConfirmationModal from "../../Modal/DeleteConfitmModal";
import { EditStatus } from "../../../../Apis/Functions/User/EditStatus";
import { DeleteUser } from "../../../../Apis/Functions/User/DeleteUser";
import BLockConfirmationModal from "../../Modal/BlockConfirmModal";

const PersonalTable = ({ userData, type }) => {
  const [userId, setUserId] = useState(null);
  const [verifyImage, setVerifyImage] = useState("");
  // modal for status
  const [open, setOpen] = useState(false);

  const handleClickOpen = (id, img) => {
    setUserId(id);
    setVerifyImage(img);
    setOpen(true);
  };

  const handleStatusModalClose = () => {
    setOpen(false);
  };
  const handleEdit = async (action) => {
    try {
      let res = await EditStatus(userId, action);
      alert(res.m);
      setOpen(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  //  modal for Delete
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (id) => {
    setUserId(id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [modalblockOpen, setModalBLockOpen] = useState(false);
  const [blockStatus, setBlockStatus] = useState("");
  const handleOpenModalBlock = (id, status) => {
    setUserId(id);
    setBlockStatus(status);
    setModalBLockOpen(true);
  };

  const handleCloseModalBlock = () => {
    setModalBLockOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      let res = await DeleteUser(userId, 0);
      setModalOpen(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const handleConfirmBlock = async () => {
    try {
      let res = await DeleteUser(userId, -1);
      setModalBLockOpen(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const handleConfirmUnblock = async () => {
    try {
      let res = await DeleteUser(userId, 1);
      setModalBLockOpen(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          p: "0 20px 0px 20px",
          boxSizing: "border-box",
          width: "100%",
          m: "auto",
        }}
      >
        <TableContainer>
          <Stack spacing={2}>
            <Table sx={{ width: "98%", m: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "start",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#9C9C9C",
                      lineHeight: "0.3",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "start",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#9C9C9C",
                      lineHeight: "0.3",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "start",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#9C9C9C",
                      lineHeight: "0.3",
                    }}
                  >
                    Username
                  </TableCell>
                  <TableCell>
                    <Typography
                      noWrap
                      sx={{
                        textAlign: "start",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#9C9C9C",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "130px",
                        "&:hover": {
                          overflow: "visible",
                        },
                      }}
                    >
                      Phone Number
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "start",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#9C9C9C",
                      lineHeight: "0.3",
                    }}
                  >
                    Email Address
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      textAlign: "start",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#9C9C9C",
                      lineHeight: "0.3",
                    }}
                  >
                    Gender
                  </TableCell>{" "}
                  {/* <TableCell
                    sx={{
                      textAlign: "start",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#9C9C9C",
                      lineHeight: "0.3",
                    }}
                  >
                    Status
                  </TableCell> */}
                  <TableCell
                    sx={{
                      textAlign: "start",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#9C9C9C",
                      lineHeight: "0.3",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData?.map((row, i) => (
                  <TableRow key={row.id}>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        lineHeight: "0.3",
                      }}
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell>
                      <Typography
                        noWrap
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                        }}
                      >
                        {row.corporate_name || row.f_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        noWrap
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                          "&:hover": {
                            overflow: "visible",
                          },
                        }}
                      >
                        {row.user_name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        lineHeight: "0.3",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "10%",
                        "&:hover": {
                          overflow: "visible",
                        },
                      }}
                    >
                      {row.phno}
                    </TableCell>
                    <TableCell>
                      <Typography
                        noWrap
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "250px",
                          "&:hover": {
                            overflow: "visible",
                          },
                        }}
                      >
                        {row.email}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        lineHeight: "0.3",
                      }}
                    >
                      {row.gender === 1 ? "Male" : "Female"}
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontFamily: "Poppins",
                        lineHeight: "0.3",
                      }}
                    >
                      <Button
                        onClick={
                          row.verification_img.status === 0
                            ? () => handleClickOpen(row.id,row?.verification_img?.verification_img)
                            : null
                        }
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                          boxSizing: "border-box",
                          p: "5px 15px 5px 15px",
                          borderRadius: "20px",
                          "&:hover": {
                            color:
                              row.verification_img.status === 0
                                ? "#000000"
                                : row.verification_img.status === -1
                                ? "#FFFFFF"
                                : row.verification_img.status === 1
                                ? "#ffffff"
                                : "#FFD0A6",
                            bgcolor:
                              row.verification_img.status === 0
                                ? "#FFD0A6"
                                : row.verification_img.status === -1
                                ? "#D65959"
                                : row.verification_img.status === 1
                                ? "#1A6B69"
                                : "#FFD0A6",
                          },
                          color:
                            row.verification_img.status === 0
                              ? "#000000"
                              : row.verification_img.status === -1
                              ? "#FFFFFF"
                              : row.verification_img.status === 1
                              ? "#ffffff"
                              : "#FFD0A6",
                          bgcolor:
                            row.verification_img.status === 0
                              ? "#FFD0A6"
                              : row.verification_img.status === -1
                              ? "#D65959"
                              : row.verification_img.status === 1
                              ? "#1A6B69"
                              : "#FFD0A6",
                        }}
                      >
                        {row.verification_img.status === 0
                          ? "Pending"
                          : row.verification_img.status === 1
                          ? "Verified"
                          : "Rejected"}
                      </Button>
                   
                      <Modal
                        open={open}
                        onClose={handleStatusModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "400px",
                            borderRadius: "30px",
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontSize: "24px",
                                fontWeight: "400",
                                color: "#858585",
                              }}
                            >
                              Verify User Image
                            </Typography>
                            <Box>
                              <img
                                onClick={handleStatusModalClose}
                                src={closeicon}
                                alt="logo"
                              />
                            </Box>
                          </Box>
                          <Box sx={{ m: "auto", mt: 3, textAlign: "center" }}>
                            <img
                              width={"45%"}
                              src={`https://api.appricot-networking.com/uploads/verification_img/${verifyImage}`}
                              alt="preview"
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              mt: 3,
                            }}
                          >
                            <CustomButton
                              onClick={() => handleEdit(1)}
                              sx={{
                                width: "45%",
                                borderRadius: "10px",
                                bgcolor: "#95DC8A",
                                "&:hover": {
                                  background: "#95DC8A",
                                },
                              }}
                            >
                              Verify
                            </CustomButton>
                            <CustomButton
                              onClick={() => handleEdit(-1)}
                              sx={{
                                width: "45%",
                                borderRadius: "10px",
                                bgcolor: "#F0BCBC",
                                "&:hover": {
                                  background: "#F0BCBC",
                                },
                              }}
                            >
                              Reject
                            </CustomButton>
                          </Box>
                        </Box>
                      </Modal>
                    </TableCell> */}
                    <TableCell>
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Box sx={{ width: "30px", height: "30px" }}>
                          <Link to={`/${type}User/${row.id}`}>
                            <img
                              height={"100%"}
                              width={"100%"}
                              style={{ cursor: "pointer" }}
                              src={eye}
                              alt="logo"
                            />
                          </Link>
                        </Box>
                        <Box sx={{ width: "30px", height: "30px" }}>
                          <img
                            onClick={() => handleOpenModal(row.id)}
                            height={"100%"}
                            width={"100%"}
                            style={{ cursor: "pointer" }}
                            src={bin}
                            alt="logo"
                          />
                        </Box>
                        {/* block  */}
                        <Box sx={{ width: "30px", height: "30px" }}>
                          <img
                            onClick={() =>
                              handleOpenModalBlock(row.id, row.status)
                            }
                            height={"100%"}
                            width={"100%"}
                            style={{ cursor: "pointer" }}
                            src={row.status == 1 ? block : redblock}
                            alt="logo"
                          />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: 40,
                  right: { xs: 0, sm: 0, md: 50 },
                }}
              ></Box>
            </Table>
          </Stack>
        </TableContainer>

        {/* modal for  delete */}
        <DeleteConfirmationModal
          open={modalOpen}
          onClose={handleCloseModal}
          onConfirmDelete={() => handleConfirmDelete(userId)}
        />
        {/* modal for block */}
        <BLockConfirmationModal
          open={modalblockOpen}
          onClose={handleCloseModalBlock}
          blockStatus={blockStatus}
          onConfirmBlock={() => {
            blockStatus === 1
              ? handleConfirmBlock(userId)
              : handleConfirmUnblock(userId);
          }}
        />
      </Box>
    </Box>
  );
};

export default PersonalTable;
