import React from "react";
import bg from "../../../../../Utils/Images/Login_bg.png";
import { Box, Typography } from "@mui/material";
import CustomInput from "../../../../../Components/Inputs/CustomInput";
import CustomButton from "../../../../../Components/Buttons/CustomButton";

const Forgot = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        objectFit: "cover",
        fill: "Background",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "cover",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "75%", sm: "60%", md: "40%", xl: "30%" },
          padding: "45px",
          bgcolor: "white",
          fontFamily: "Segoe UI",
          borderRadius: "30px",
          m: "auto",
          boxShadow: "10px 10px 26px 0px #0000001A",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            fontSize: { xs: "25px", md: "32px", xl: "39px" },
            fontWeight: "700",
            lineHeight: "47px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          Forgot Password
        </Typography>
        <Box
          sx={{
            width: "80%",
            textAlign: "left",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Segoe UI",
              fontSize: { xs: "15px", md: "20px", xl: "25px" },
              fontWeight: "400",
              lineHeight: "30px",
              textAlign: "left",
            }}
          >
            Enter Your Email to Get Reset Password Link
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            mt: "10px",
          }}
        >
          <CustomInput
            variant="outlined"
            placeholder="Email Address"
            sx={{
              mt: { xs: "35px", md: "30px", xl: "30px" },
              textAlign: "center",
              bgcolor: "#F2F2F2",
              borderRadius: "15px",
            }}
          />
          <CustomButton
            sx={{
              mt: "15px",
            }}
          >
            Reset
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Forgot;
