import { API, API_GET, API_POST } from "../../config"

export const GetDesignation = () => {
  return (
    API_GET(API.Designation)
  )
}
export const DesignationDelete = (id) => {
  const f = new FormData();
  f.append("id", id);
  return API_POST(API.DesignationDelete,f);
};

export const AddDesignation = (data) => {
  const f = new FormData();
  f.append("name", data);
  return API_POST(API.AddDesignation,f);
};

export const EditDesignation = (data,id) => {
  const f = new FormData();
  f.append("id", id);
  f.append("name", data);
  return API_POST(API.editDesignation,f);
};
