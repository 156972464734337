import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import OtherDetails from "./Components/OtherDetails";
import Details from "./Components/Details";
import Photos from "./Components/Photos";
import Servicelooking from "./Components/ServiceLooking";
import { useNavigate, useParams } from "react-router-dom";
import { GetSingleUser } from "../../../../Apis/Functions/User/GetSingleUser";
import CustomButton from "../../../../Components/Buttons/CustomButton";
import BLockConfirmationModal from "../../../../Components/Dashboard/Modal/BlockConfirmModal";
import { DeleteUser } from "../../../../Apis/Functions/User/DeleteUser";
import vector from "../../../../Utils/Images/Vector.png";

const UserDetails = () => {
  const { id } = useParams();
  const [singleUser, setSingleUser] = useState("");
  const [userId, setUserId] = useState(null);
  const [modalblockOpen, setModalBLockOpen] = useState(false);
  const [blockStatus, setBlockStatus] = useState("");
  const navigate = useNavigate();
  const handleSingleUser = async () => {
    try {
      let res = await GetSingleUser(id);

      setSingleUser(res.r);
    } catch (error) {
      console.log(error);
    }
  };
  const handleConfirmBlock = async () => {
    try {
      let res = await DeleteUser(userId, -1);
      setModalBLockOpen(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const handleConfirmUnblock = async () => {
    try {
      let res = await DeleteUser(userId, 1);
      setModalBLockOpen(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSingleUser();
  }, []);

  const handleOpenModalBlock = (id, status) => {
    setUserId(id);
    setBlockStatus(status);
    setModalBLockOpen(true);
  };

  const handleCloseModalBlock = () => {
    setModalBLockOpen(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        p: 3,
        boxSizing: "border-box",
        width: "98%",
        margin: "auto",
      }}
    > <Box sx={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
      <Box
        onClick={handleBack}
        sx={{ cursor: "pointer", width: "50px", height: "50px" }}
      >
        <img style={{ width: "100%", height: "100%" }} src={vector} alt="" />
      </Box>

      <CustomButton
        onClick={() => handleOpenModalBlock(singleUser?.id, singleUser?.status)}
        sx={{
          width: "150px",
          ml: "10px",
          bgcolor: singleUser?.status == -1 ? "red" : "#8ADCD9",
          "&:hover": {
            background: singleUser?.status == -1 ? "red" : "#8ADCD9",
          },
        }}
      >
        {singleUser?.status == -1 ? "Unblock" : "Block"}
        {/* Block User */}
      </CustomButton>
      </Box>

      <Grid container spacing={2}>
        {/* First Column */}

        <Grid xs={12} sm={6} md={4} lg={4} xl={3} item>
          <Details singleUser={singleUser} />
        </Grid>

        {/* Second Column (Spans two rows) */}
        <Grid xs={12} sm={6} md={4} lg={4} xl={3} item>
          <Servicelooking singleUser={singleUser} />
        </Grid>

        {/* Third Column */}
        <Grid xs={12} sm={6} md={4} lg={4} xl={3} item>
          <Photos singleUser={singleUser} />
        </Grid>

        {/* Fourth Column */}
        <Grid xs={12} sm={6} md={4} lg={4} xl={3} item>
          <OtherDetails singleUser={singleUser} />
        </Grid>
      </Grid>
      <BLockConfirmationModal
        open={modalblockOpen}
        onClose={handleCloseModalBlock}
        blockStatus={blockStatus}
        onConfirmBlock={() => {
          blockStatus === 1
            ? handleConfirmBlock(userId)
            : handleConfirmUnblock(userId);
        }}
      />
    </Box>
  );
};

export default UserDetails;
