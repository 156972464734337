import { Box, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { LuSearch } from "react-icons/lu";
import { UserSearch } from "../../../Apis/Functions/User/UserSearch";
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "../../Buttons/CustomButton";
const Searchbar = ({ setSearchResults, type }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  const handleSubmit = async () => {
    try {
      let res = await UserSearch(searchQuery, type);
      setSearchResults(res.r);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <TextField
        value={searchQuery}
        onChange={handleInputChange}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LuSearch />
            </InputAdornment>
          ),
        }}
        sx={{
          bgcolor: "#FBFBFB",
          borderRadius: "10px",
          width: { sm: "100%", md: "150%" },
        }}
      />
      <CustomButton
        onClick={handleSubmit}
        sx={{ width: "50px", height: "50px" }}
        variant="contained"
      >
        <SearchIcon />
      </CustomButton>
    </Box>
  );
};

export default Searchbar;
