import axios from "axios";
import { API } from "../config";


const loginAPI = async (
  email,
  password,
  setIsLoggedIn,
  setLoading,
  navigate
) => {
  try {
    setLoading(true);
    const response = await axios.post(API.login, {
      email,
      password,
    });

    if (response.status === 200) {
      setLoading(false);
      localStorage.setItem("token", JSON.stringify(response.data.token));
      setIsLoggedIn(true);
      navigate("/");
    } else {
      setLoading(false);
      alert('Invalid Email or Password')

    }
  } catch (error) {
    // if any error
    setLoading(false);
    alert('Invalid Email or Password')
  }
};

export default loginAPI;
