import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import UserTable from "../../../../Components/Dashboard/Tables/UserTable/UserTable";
import { Link } from "react-router-dom";
import { GetBusinessUser } from "../../../../Apis/Functions/User/GetBusinessUser";
import { GetPrivateUser } from "../../../../Apis/Functions/User/GetPrivateUser";
import { GetAnalytics } from "../../../../Apis/Functions/User/GetAnalytics";

const DashboardHome = () => {
  const [BusinessUserData, setBuisenessUserData] = useState([]);
  const [PrivateUserData, setPrivateUserData] = useState([]);
  const [loading, setloading] = useState(false);
  const [analytics, setAnalytics] = useState([]);

  const handleBusinessUser = async () => {
    try {
      setloading(true);
      let res = await GetBusinessUser();
      console.log(res.r);
      setloading(false);
      setBuisenessUserData(res.r);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };
  const handleAnalytics = async () => {
    try {
      let res = await GetAnalytics();
      console.log(res.r);
      setAnalytics(res.r);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePrivateUser = async () => {
    setloading(true);
    try {
      let res = await GetPrivateUser();
      console.log(res.r);
      setloading(false);
      setPrivateUserData(res.r);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleBusinessUser();
    handlePrivateUser();
    handleAnalytics();
  }, []);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          bgcolor: "#EDEDED",
          height: "100vh",
          overflow: "auto",
        }}
      >
        {" "}
        {loading ? (
          <Box sx={{ position: "absolute", top: "50%", left: "55%" }}>
            <CircularProgress></CircularProgress>
          </Box>
        ) : (
          <Box sx={{ ml: { xs: "1px", md: "300px" }, width: "100%", p: 2 }}>
            <Grid container spacing={2} justifyContent={"center"}>
              {/* First Row */}
              <Grid item sx={{ width: { sm: "25%", md: "33%", lg: "33%" } }}>
                <Paper
                  sx={{
                    borderRadius: "20px",
                    textAlign: "left",
                    paddingTop: "5px",
                    height: "125px",
                    p: "15px",
                    boxSizing: "border-box",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      fontSize: { sm: "12px", md: "16px" },
                      letter: "2%",
                      lineHeight: "23.2px",
                      fontWeight: "500",
                      boxSizing: "border-box",
                    }}
                  >
                    Total Users
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      fontSize: { xs: "25px", md: "40px" },
                      letter: "2%",
                      lineHeight: "58px",
                      fontWeight: "500",
                      mt: "10px",
                    }}
                  >
                    {analytics?.total_users}
                  </Typography>
                </Paper>
              </Grid>

              <Grid item sx={{ width: { sm: "25%", md: "33%", lg: "33%" } }}>
                <Paper
                  sx={{
                    borderRadius: "20px",
                    textAlign: "left",
                    paddingTop: "5px",
                    height: "125px",
                    p: "15px",
                    boxSizing: "border-box",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      fontSize: { sm: "12px", md: "16px" },
                      letter: "2%",
                      lineHeight: "23.2px",
                      fontWeight: "500",
                      boxSizing: "border-box",
                    }}
                  >
                    Private Users
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      fontSize: { xs: "25px", md: "40px" },
                      letter: "2%",
                      lineHeight: "58px",
                      fontWeight: "500",
                      mt: "10px",
                    }}
                  >
                    {analytics?.total_private_users}
                  </Typography>
                </Paper>
              </Grid>

              <Grid item sx={{ width: { sm: "25%", md: "33%", lg: "33%" } }}>
                <Paper
                  sx={{
                    borderRadius: "20px",
                    textAlign: "left",
                    paddingTop: "5px",
                    height: "125px",
                    p: "15px",
                    boxSizing: "border-box",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      fontSize: { sm: "12px", md: "16px" },
                      letter: "2%",
                      lineHeight: "23.2px",
                      fontWeight: "500",
                      boxSizing: "border-box",
                      textWrap: "wrap",
                    }}
                  >
                    Business Users
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      fontSize: { xs: "25px", md: "40px" },
                      letter: "2%",
                      lineHeight: "58px",
                      fontWeight: "500",
                      mt: "10px",
                    }}
                  >
                    {analytics?.total_business_users}
                  </Typography>
                </Paper>
              </Grid>

              {/* Second Row */}
              <Grid item sx={{ width: { md: "49%", lg: "49%" } }}>
                <Paper sx={{ height: "480px", borderRadius: "20px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      boxSizing: "border-box",
                      padding: 3,
                      height: { sm: "30px", md: "50px" },
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontFamily: "Manrope",
                    }}
                  >
                    <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                      Recent Private User
                    </Typography>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={"/privateUser"}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#01696B",
                        }}
                      >
                        View All {">"}
                      </Typography>
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      overflow: "auto",
                      height: "420px",
                      width: { xs: "400px", sm: "500px", md: "100%" },
                    }}
                  >
                    <UserTable userData={PrivateUserData} />
                  </Box>
                </Paper>
              </Grid>
              <Grid item sx={{ width: { md: "49%", lg: "49%" } }}>
                <Paper
                  sx={{
                    height: "480px",
                    overflow: "auto",
                    borderRadius: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      boxSizing: "border-box",
                      padding: 3,
                      height: { sm: "30px", md: "50px" },
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontFamily: "Manrope",
                    }}
                  >
                    <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                      Recent Business User
                    </Typography>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={"/businessUser"}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#01696B",
                        }}
                      >
                        View All {">"}
                      </Typography>
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      overflow: "auto",
                      height: "420px",
                      width: { xs: "400px", sm: "500px", md: "100%" },
                    }}
                  >
                    <UserTable userData={BusinessUserData} />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default DashboardHome;
