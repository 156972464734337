import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import bin from "../../../../Utils/Images/bin.svg";
import DeleteConfirmationModal from "../../Modal/DeleteConfitmModal";
import { DeleteConatct, DeleteContact } from "../../../../Apis/Functions/ContactList/DeleteContactList";

const ContactTable = ({ userData, handleContactList }) => {
  const [open, setOpen] = useState(false);
  const [contactID, setContactId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleOpenModal = (id) => {
    setOpen(true);
    setContactId(id);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleConfirmDelete = async (reportId) => {
    console.log(reportId);
    try {
      let res = await DeleteContact(contactID, 0);
      if (res.s) {
        setOpen(false);
        handleContactList();
        showSnackbar("Delete successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <DeleteConfirmationModal
        open={open}
        onClose={handleCloseModal}
        onConfirmDelete={() => handleConfirmDelete(contactID)}
      />
      <Box
        sx={{
          p: "0 20px 0px 20px",
          boxSizing: "border-box",
          overflow: "auto",
          "&:hover": {
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "3px",
            },
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "start",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  textAlign: "center",
                }}
              >
                #
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "start",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  textAlign: "center",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "left",
                  position: "sticky",
                  top: 0,
                  bgcolor: "white",
                  zIndex: 1,
                  textAlign: "center",
                }}
              >
                Phone No.
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  lineHeight: "0.3",
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#9C9C9C",
                    bgcolor: "white",
                    textAlign: "center",
                  }}
                >
                  Email
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "center",
                }}
              >
                Feedback
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "center",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData?.map((row, i) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "100%",
                      "&:hover": {
                        overflow: "visible",
                      },
                      textAlign: "center",
                    }}
                  >
                    {i + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "100%",
                      textAlign: "center",
                      "&:hover": {
                        overflow: "visible",
                      },
                    }}
                  >
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "120px",
                      textAlign: "center",
                      "&:hover": {
                        overflow: "visible",
                      },
                    }}
                  >
                    {row.phno}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textAlign: "center",
                      maxWidth: "300px",
                      "&:hover": {
                        overflow: "visible",
                      },
                    }}
                  >
                    {row.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.feedback} arrow>
                    <Typography
                      noWrap
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        overflow: "hidden",
                        maxWidth: "300px",
                      }}
                    >
                      {row.feedback}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        onClick={(e) => handleOpenModal(row.id)}
                        sx={{ width: "30px", height: "30px" }}
                      >
                        <img
                          height={"100%"}
                          width={"100%"}
                          style={{ cursor: "pointer" }}
                          src={bin}
                          alt="logo"
                        />
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default ContactTable;
