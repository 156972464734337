import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import CustomButton from "../../Buttons/CustomButton";
const BLockConfirmationModal = ({
  open,
  onClose,
  onConfirmBlock,
  blockStatus,
}) => {
  
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          outline: "none",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "20px",
          gap: "20px",
          boxShadow: 24,
          p: 4,
        }}
      >
        {blockStatus === 1 ? (
          <Typography variant="h6" component="h2" gutterBottom>
            Are you sure you want to Block this user?
          </Typography>
        ) : (
          <Typography variant="h6" component="h2" gutterBottom>
            Are you sure you want to Unblock this user?
          </Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <BlockIcon sx={{ height: "80px", color: "#8ADCD9", width: "70px" }} />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", gap: "20px", mt: 2 }}
        >
          {blockStatus === 1 ? (
            <CustomButton
              sx={{ bgcolor: "#8ADCD9" }}
              onClick={onConfirmBlock}
              variant="contained"
            >
              Yes, Block it!
            </CustomButton>
          ) : (
            <CustomButton
              sx={{ bgcolor: "#8ADCD9" }}
              onClick={onConfirmBlock}
              variant="contained"
            >
              Yes, Unblock it!
            </CustomButton>
          )}

        
          <CustomButton
            sx={{ bgcolor: "#8ADCD9", color: "black" }}
            onClick={onClose}
            variant="contained"
          >
            Cancel
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default BLockConfirmationModal;
