import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Searchbar from "../../../../Components/Dashboard/Searchbar/Searchbar";
import PersonalTable from "../../../../Components/Dashboard/Tables/PersonalTable.jsx/PersonalTable";
import { API } from "../../../../Apis/config";
import { GetPrivateUser } from "../../../../Apis/Functions/User/GetPrivateUser";
import CustomButton from "../../../../Components/Buttons/CustomButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const PrivateUser = () => {
  const [userData, setUserData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const handlePage = (action) => {
    setPage((prev) => prev + action * 15);
  };
  

  const handlePrivateUser = async () => {
    try {
      setLoading(true);
      let res = await GetPrivateUser(page);
      console.log(res.r);
      setLoading(false);
      setUserData(res.r);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handlePrivateUser();
  }, [page]);

  return (
    <Box>
      {loading ? (
        <Box sx={{ position: "absolute", top: "50%", left: "55%" }}>
          <CircularProgress></CircularProgress>
        </Box>
      ) : (
        <Box
          sx={{
            ml: { xs: "1px", md: "300px" },
            p: 3,
            boxSizing: "border-box",
            bgcolor: "#EDEDED",
            height: "100vh",
          }}
        >
          {/* Searchbar  */}
          <Box
            sx={{
              alignItems: "left",
              display: "flex",
              ml: "20px",
            }}
          >
            <Searchbar setSearchResults={setSearchResults} type={1} />
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                sx={{
                  borderRadius: "50%",
                  minWidth: "unset",
                  width: "50px",
                  height: "50px",
                  bgcolor: "#999999",
                  "&:hover": {
                    bgcolor: "#999999", // Change background color on hover
                  },
                }}
                onClick={() => handlePage(-1)}
                disabled={page === 0}
              >
                <NavigateNextIcon
                  sx={{
                    transform: "rotate(180deg)",
                  }}
                />
              </CustomButton>
              <CustomButton
                sx={{
                  borderRadius: "50%",
                  minWidth: "unset",
                  width: "50px",
                  height: "50px",
                  bgcolor: "#999999",
                  "&:hover": {
                    bgcolor: "#999999", // Change background color on hover
                  },
                }}
                onClick={() => handlePage(+1)}
                disabled={userData.length < 15}
              >
                <NavigateNextIcon />
              </CustomButton>
            </Box>
          </Box>
          {/* PersonalTable */}
          <Box
            sx={{
              bgcolor: "#FBFBFB",
              width: "98%",
              m: "auto",
              mt: 2,
              borderRadius: "20px",
              height: "92%",
              overflow: "auto",
              "&:hover": {
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "3px",
                },
              },
            }}
          >
            <PersonalTable
              url={API.PrivateUrl}
              userData={searchResults.length ? searchResults : userData}
              setUserData={setUserData}
              handlePrivateUser={handlePrivateUser}
              type={"private"}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PrivateUser;
