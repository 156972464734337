import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const UserTable = ({ userData }) => {
  return (
    <Box>
      <Box
        sx={{
          p: "0 20px 0px 20px",
          boxSizing: "border-box",
          height: "75vh",
          overflow: "auto",
          "&:hover": {
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "3px",
            },
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "start",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                  textAlign: "left",
                  position: "sticky",
                  top: 0,
                  bgcolor: "white",
                  zIndex: 1,
                }}
              >
                Username
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor:'white',
                  lineHeight: "0.3",
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#9C9C9C",
                    bgcolor: "white",
                  }}
                >
                  Phone Number
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  bgcolor: "white",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9C9C9C",
                  lineHeight: "0.3",
                }}
              >
                Email Address
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "100%",
                      "&:hover": {
                        overflow: "visible",
                      },
                    }}
                  >
                    {row.corporate_name ||row.f_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "85px",
                      "&:hover": {
                        overflow: "visible",
                      },
                    }}
                  >
                    { row.user_name}
                  </Typography>
                </TableCell>
                <TableCell >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "90px",
                      "&:hover": {
                        overflow: "visible",
                      },
                    }}
                  >
                    {row.phno}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "125px",

                      "&:hover": {
                        overflow: "visible",
                      },
                    }}
                  >
                    {row.email}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default UserTable;
