import axios from "axios";

export const API = {
  // BASE
  BaseURL: "https://api.appricot-networking.com/",
  // AUTH
  login: "/api/auth/admin-login",

  // USER
  userbyID: "/api/user/get-by-id?id=2&lat=0&lang=0",
  UserStatus: "/api/admin/user-verification",
  UserDelete: "/api/user/account-status",
  UserAnaytics: "/api/admin/analytics",
  // PrivateUser
  PrivateUrl: `/api/user/get-all?account_type=1`,

  // BuisnessUser
  BusinesUrl: ``,

  // Contact list
  GetContact: "/api/contact/get",
  deletecontact:"/api/contact/delete",

  // Report List
  Reportlist: "/api/user/report/report-list?count:10",
  deletereport: "/api/user/report/report-delete",

  // List
  Designation: "/api/list/designation-list",
  AddDesignation: "/api/list/designation-add",
  editDesignation:"/api/list/designation-update",
  DesignationDelete: "/api/list/designation-delete",

  Service: "/api/list/service-list",
  AddService: "/api/list/service-add",
  editservice:"/api/list/service-update",
  ServiceDelete: "/api/list/service-delete",

  Experience: "/api/list/experience-list",
  AddExperience: "/api/list/experience-add",
  editExperience:"/api/list/experience-update",
  ExperienceDelete: "/api/list/experience-delete",

  Education: "/api/list/education-list",
  AddEducation: "/api/list/education-add",
  editEducation:"/api/list/education-update",
  EducationDelete: "/api/list/education-delete",

  // FAQ
  AddFaq: "/api/faq/add",
  DeleteFaq: "/api/faq/delete",
  EditFaq: "/api/faq/update",
  GetFaq: "/api/faq/get",
};

const Axios = axios.create({
  baseURL: API.BaseURL,
});

export const API_GET_ID = async (url, id) => {
  try {
    const token = localStorage.getItem("token");
    const apikey = localStorage.getItem("apikey");

    const { data } = await Axios.get(url, {
      params: {
        id: id,
        token: token,
        apikey: apikey,
      },
      headers: {
        // Add any headers you need here
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const API_GET = async (url) => {
  try {
    const token = localStorage.getItem("token");
    const apikey = localStorage.getItem("apikey");
    // const uri = url + "token=" + token + "&apikey=" + apikey;

    const { data } = await Axios.get(url, {
      params: {
        apikey,
        token,
      },
      headers: null,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const API_POST = async (url, data) => {
  const token = localStorage.getItem("token");
  const apikey = localStorage.getItem("apikey");
  try {
    const res = await Axios.post(url, data, {
      headers: null,
      params: { apikey, token },
    });

    return res.data;
  } catch (error) {
    return error;
  }
};
