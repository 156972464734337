// AllRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Forgot from "../Auth/ForgotPassword/Component/Forgot/Forgot";
import Login from "../Auth/Login/Component/Login/Login";
import UserDetails from "../Dashboard/Component/UserDetails/UserDetails";
import PrivateRoute from "./PrivateRoutes";
import DashboardRoutes from "./DashboardRoutes";

const AllRoutes = () => {
  return (
    <Routes>
          <Route
        path="/*"
        element={
          <PrivateRoute>
            <DashboardRoutes />
          </PrivateRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route
        path="/privateUser/:id"
        element={
          <PrivateRoute>
            <UserDetails />
          </PrivateRoute>
        }
      />
       <Route
        path="/businessUser/:id"
        element={
          <PrivateRoute>
            <UserDetails />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
