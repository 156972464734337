import {  API_GET } from "../../config";

export const UserSearch = (params,type) => {
  const queryParams = new URLSearchParams({
    search: params,
    account_type: type,
  }).toString();
  const urlWithParams = `/api/user/get-all?${queryParams}`;

  return API_GET(urlWithParams);
};
