import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReportTable from "../../../Components/Dashboard/Tables/ReportTable/ReportTable";
import { GetReportList } from "../../../Apis/Functions/ReportList/GetReportList";

const ReportedList = () => {
  const [report, setReport] = useState([]);
  const [type, setType] = useState("");
  const handleReportList = async () => {
    try {
      let res = await GetReportList();
      setReport(res.r);
      if (res?.r?.report_to_details?.account_type === 1) {
        setType("private");
      } else {
        setType("business");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleReportList();
  }, []);
  console.log(report);
  return (
    <>
      <Box
        sx={{
          ml: { xs: "1px", md: "300px" },
          boxSizing: "border-box",
          bgcolor: "#EDEDED",
          overflow: "auto",
          height: "100vh",
          p: 3,
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            bgcolor: "#fff",
            height: "90vh",
            borderRadius: "10px",
            overflow: "auto",
          }}
        >
          <ReportTable handleReportList={handleReportList} type={type} data={report} />
        </Box>
      </Box>
    </>
  );
};

export default ReportedList;
