import { Button, styled } from "@mui/material";

const CustomButtons = styled(Button)({
  background: "#8ADCD9", // Change the background color
  padding: "18px 15px 18px 15px",
  borderRadius: "10px",
  fontFamily: "Segoe UI",
  fontSize: "20px",
  color: "#1D1920",
  textTransform: "capitalize",
  fontWeight:'400',
  wordSpacing:"normal",
  lineHeight:'24px',
  "&:hover": {
    background: "#8ADCD9", // Change the background color on hover
  },
});

const CustomButton = ({ ...props }) => {
  return <CustomButtons {...props} />;
};
export default CustomButton;
