import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const Photos = ({ singleUser }) => {
  console.log("s", singleUser);
  return (
    <Box>
      <Paper
        sx={{
          p: 2,
          textAlign: "center",
          color: "text.secondary",
          borderRadius: "23px",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#858585",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Photos
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={0}>
            {singleUser?.account_type === 1 ? (
              singleUser?.user_images?.map((e, i) => (
                <Grid key={i} xs={4} spacing={5} sx={{ mt: 4 }}>
                  <Box
                    sx={{
                      width: "80%",
                      m: "auto",
                    }}
                  >
                    <img
                      width={"100%"}
                      style={{
                        borderRadius: "10px",
                      }}
                      src={`https://api.appricot-networking.com/uploads/profile_img/${e.image}`}
                      alt="preview"
                    />
                  </Box>
                </Grid>
              ))
            ) : (
              <Box
                sx={{
                  width: "80%",
                  m: "auto",
                }}
              >
                <img
                  width={"100%"}
                  style={{
                    borderRadius: "10px",
                  }}
                  src={`https://api.appricot-networking.com/uploads/business_logo/${singleUser?.business_logo}`}
                  alt="preview"
                />
              </Box>
            )}
          </Grid>
        </Box>
      </Paper>
      <Paper
        sx={{
          p: 2,
          textAlign: "center",
          color: "text.secondary",
          borderRadius: "23px",
          mt: "10px",
          // height:'400px',
          // overflow:'auto'
        }}
      >
        <Typography
          sx={{
            color: "#858585",
            textAlign: "left",
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "18px",
          }}
        >
          Subcrtiption Details
        </Typography>
        <Box>
          <Typography
            sx={{
              color: "#858585",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "15px",
            }}
          >
            Transaction id
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "10px",
            }}
          >
            {singleUser?.subscription_details?.transaction_id}
          </Typography>
          <Divider sx={{ width: "100%", mt: 2 }} />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#858585",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "15px",
            }}
          >
            Plan Name
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "10px",
            }}
          >
            {singleUser?.subscription_details?.plan_name}
          </Typography>
          <Divider sx={{ width: "100%", mt: 2 }} />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#858585",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "15px",
            }}
          >
            Price
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "10px",
            }}
          >
            {singleUser?.subscription_details?.price}
          </Typography>
          <Divider sx={{ width: "100%", mt: 2 }} />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#858585",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "15px",
            }}
          >
            Currency
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "10px",
            }}
          >
            {singleUser?.subscription_details?.currency}
          </Typography>
          <Divider sx={{ width: "100%", mt: 2 }} />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#858585",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "15px",
            }}
          >
            Start Date
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              textAlign: "left",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              mt: "10px",
            }}
          >
          {singleUser?.subscription_details?.start_date}
          </Typography>
          <Divider sx={{ width: "100%", mt: 2 }} />
        </Box>
      </Paper>
    </Box>
  );
};

export default Photos;
