import { TextField, styled } from "@mui/material";

const CustomInputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFF",
      borderRadius:"10px" // Change the outline color
    },
    "&:hover fieldset": {
      borderColor: "#FFFF", // Change the outline color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFF", // Change the outline color when focused
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F2F2F2", // Change the background color      },
    },
  },
  "& .MuiInputBase-input": {
    "&::placeholder": {
      color: "#5F5F5F",
      opacity: "1",
      fontSize: { xs: "8px", md: "10px", xl: "20px" },
      fontWeight: "400",
      lineHeight: "24px",
      fontFamily: "Segoe UI",
    },
  },
});

const CustomInput = ({ ...props }) => {
  return <CustomInputField {...props} />;
};

export default CustomInput;
