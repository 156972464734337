import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetContacts } from "../../../Apis/Functions/ContactList/GetContactList";
import ContactTable from "../../../Components/Dashboard/Tables/ContactsTable/ContactsTable";

const ContactList = () => {
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const handleContactList = async () => {
    try {
      setLoading(true);
      let res = await GetContacts();
      setLoading(false);
      setContacts(res.r);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleContactList();
  }, []);
  console.log(contacts);
  return (
    <>
      <Box
        sx={{
          ml: { xs: "1px", md: "300px" },
          boxSizing: "border-box",
          bgcolor: "#EDEDED",
          overflow: "auto",
          height: "100vh",
          p: 3,
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            bgcolor: "#fff",
            height: "90vh",
            borderRadius:'10px',
            overflow: "auto",
          }}
        >
          <ContactTable handleContactList={handleContactList} userData={contacts} />{" "}
        </Box>
      </Box>
    </>
  );
};

export default ContactList;
