import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import bin from "../../../Utils/Images/bin1.png";
import CustomButton from "../../Buttons/CustomButton";
import CustomInput from "../../Inputs/CustomInput";
import close from "../../../Utils/Images/Close.svg";
import pen from "../../../Utils/Images/Edit.png";
import {
  AddServices,
  EditServices,
  ServiceDelete,
} from "../../../Apis/Functions/List/Service";
import UpdateModal from "../../Dashboard/Modal/UpdateModal";

const SettingCard = ({ title, data }) => {
  const [handleInput, setHandleInput] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aloading, setaLoading] = useState(false);
  const [settingId, setSettingId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [text, setText] = useState("");

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleOpenModal = (e) => {
    setEditOpen(true);
    setSettingId(e.id);
    setText(e.name || e.experience);
  };

  const AddData = async (title) => {
    console.log(title);
    try {
      if (handleInput.trim().length) {
        setaLoading(true);
        let res = await AddServices(handleInput.trim(), title);
        setaLoading(false);
        setOpen(false);
        window.location.reload();
        console.log(res);
      } else {
        alert("Title is Required");
      }
    } catch (error) {
      setaLoading(false);
      console.log(error);
    }
  };

  const handleConfirmEdit = async () => {
    console.log(title);

    try {
      if (text.trim().length) {
        setaLoading(true);
        let res = await EditServices(text.trim(), settingId, title);
        handleCloseEdit();
        setaLoading(false);
        window.location.reload();
        setOpen(false);
      } else {
        alert("Title is Required");
      }
    } catch (error) {
      setaLoading(false);
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setEditOpen(false);
  };

  const handleDelete = async (id, title) => {
    setLoading(true);
    try {
      let res = await ServiceDelete(id, title);
      console.log(res);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: { md: "45%", lg: "24%" },
        bgcolor: "white",
        boxSizing: "border-box",
        p: "15px",
        borderRadius: "20px",
        maxHeight: "700px",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Neue Montreal",
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "28.8px",
            letter: "3%",
          }}
        >
          {title}
        </Typography>
        <Button
          sx={{
            bgcolor: "#8ADCD9",
            color: "#000000",
            textTransform: "capitalize",
            fontFamily: "Neue Montreal",
            fontWeight: "400",
            fontSize: "20px",
            borderRadius: "10px",
            p: { md: "2px 15px 2px 15px" },
            "&:hover": {
              bgcolor: "#8ADCD9",
              color: "#000000",
            },
          }}
          variant="contained"
          onClick={() => handleClickOpen(title)}
        >
          ADD
        </Button>
      </Box>
      <Divider sx={{ mt: "20%", border: "1px solid #C4C4C4" }} />
      {loading ? (
        <Box sx={{ mt: "20%" }}>
          <CircularProgress></CircularProgress>
        </Box>
      ) : (
        <Box>
          {data.map((e, i) => (
            <Box
              key={e.id + 1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: "20px",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Neue Montreal",
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                >
                  {i + 1}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Neue Montreal",
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "19.2px",
                    wordBreak: "break-all",
                    wordWrap: "break-word",
                  }}
                >
                  {title === "Experience" ? e.experience : e.name}
                </Typography>
              </Box>{" "}
              <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                <Box sx={{ width: "30px", height: "30px" }}>
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "6px",
                      opacity: "0.5",
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpenModal(e)}
                    src={pen}
                    alt="photo"
                  />
                </Box>
                <Box sx={{ width: "30px", height: "30px" }}>
                  <img
                    style={{
                      borderRadius: "6px",
                      opacity: "0.5",
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={() => handleDelete(e.id, title)}
                    src={bin}
                    alt="phot"
                  />
                </Box>
              </Box>
            </Box>
          ))}
          {/* modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "400px",
                borderRadius: "30px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Neue Montreal",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Add {title}
                </Typography>
                <img onClick={handleClose} src={close} alt="logo" />
              </Box>

              <CustomInput
                // value={text}
                placeholder={title}
                onChange={(e) => setHandleInput(e.target.value)}
                sx={{
                  width: "100%",
                  bgcolor: "#F2F2F2",
                  borderRadius: "10px",
                  mt: "20px",
                }}
              />
              <CustomButton
                onClick={() => AddData(title)}
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  mt: "20px",
                }}
              >
                {aloading ? <CircularProgress></CircularProgress> : "Add"}
              </CustomButton>
            </Box>
          </Modal>
          <UpdateModal
            open={editOpen}
            onClose={handleCloseEdit}
            text={text}
            setText={setText}
            onConfirmUpdate={() => handleConfirmEdit(settingId)}
          />
        </Box>
      )}
    </Box>
  );
};

export default SettingCard;
