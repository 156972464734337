// AuthContext.js

import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginAPI from "../../Apis/Functions/login";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // from loginApi
  const loginParent = (email, password) => {
    loginAPI(email, password, setIsLoggedIn, setLoading, navigate);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    localStorage.removeItem("apikey");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, loginParent, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
