import { API, API_POST } from "../../config";


export const EditFaq = (payload, id) => {
  console.log(payload)
  const f = new FormData();

  f.append("que_id", id);
  f.append("question", payload.editquestion);
  f.append("answer", payload.editanswer);

  return API_POST(API.EditFaq, f);
};
